/*------------ Home page css start ---------*/

.navbar-header {
    padding: 12px 52px;
    position: fixed;
    background-color: #fff;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: 6px 6px 60px 0px rgb(0 0 0 / 10%);
}
.logo-web{
    outline: none !important;
}
.logo-web img {
    height: 80px;
    width: 79px;
}

.head-menu a {
    padding: 8px 8px;
    color: #000;
    font-weight: 600;
    font-size: 16px;
    margin: 0 8px;
    text-decoration: none;
}

.head-menu a:hover,
.head-menu a.active {
    color: #d81f1f;
}

.social-media a:hover {
    background-color: #d81f1f;
}

.social-media a {
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    margin-right: 13px;
    text-decoration: none;
    background-color: #fff3;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    line-height: 39px;
    transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
}

.social-media {
    margin-left: 15px;
}

.header-nav {
    line-height: 50px;
}

.request-detail .social-media-icon {
    padding: 0;
}

.request-detail .social-media-icon a {
    height: 44px;
    width: 44px;
    line-height: 42px;
    font-size: 21px;
}

.main-login {
    color: #fff !important;
    border-radius: 7px;
    font-size: 17px;
    display: block;
    width: 140px;
    text-align: center;
    font-weight: bold;
    height: 50px;
    line-height: 49px;
    text-decoration: none !important;
    background-color: #d81f1f;
    float: right;
    outline: none !important;
    margin-left: 15px;
}

.app-img {
    width: auto;
    height: 650px;
}

.Waitlist-section {
    padding: 0px 55px;
}

.image-title-wrapper h4 {
    background-color: rgba(255, 255, 255, 0);
    color: #fff;
    font-size: 65px;
    box-shadow: .5em 0 0 rgba(255, 255, 255, 0), -.5em 0 0 rgba(255, 255, 255, 0);
    font-weight: bold;
    margin-bottom: 35px;
    line-height: 65px;
}

.image-title-wrapper {
    padding-left: 0px;
    padding-top: 80px;
    color: #d81f1f;
    font-size: 17px;
    font-weight: 600;
}

.wrap-p {
    font-size: 17px;
    line-height: 1.45em;
    margin-bottom: 40px;
    color: #8e9bac;
    font-weight: normal;
}

.wrap-p span {
    font-weight: bold;
}

.Index-page-content {
    padding: 100px 85px;
}

.interested-tainer-block,
.Index-page-content,
.goals-block,
.howtocommonhealth -block {
    background-color: #fff;
}

.Index-page-content h1 {
    font-size: 43px;
    font-weight: bold;
    line-height: 63px;
    margin-bottom: 25px;
}

.trainer-content {
    padding-top: 65px;
}

.Index-page-content p {
    margin-bottom: 0;
    font-size: 17px;
    text-align: justify;
}

.choose-commonhealth {
    padding: 80px 55px;
    background: rgb(254, 246, 244)
}

.choose-content h3,
.our-mission-content h3,
.interested-tainer-content h3,
.trusted-content h3,
.contactus-content h3 {
    color: #d81f1f;
    text-transform: uppercase;
    font-size: 17px;
    margin-bottom: 15px;
}

.choose-content h2 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 65px;
}

.choose-content p {
    font-size: 25px;
    margin-bottom: 70px;
}

.choose-img img {
    width: auto;
    height: 700px;
    margin: 0 auto;
    display: block;
}

.ch-content h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}

.ch-content p {
    font-size: 17px;
    margin-bottom: 48px;
    text-align: justify;
}

.choose-content {
    text-align: center;
}

.choose-block {
    padding-left: 15px;
}

.p-training-block {
    background-color: #141b22;
    padding: 45px 70px;
}

.appstore img {
    width: 210px;
    height: 65px;
}

.p-training-text {
    font-size: 23px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 55px;
    line-height: 1.33em;
    padding-top: 165px;
    text-align: center;
}

.our-mission {
    padding: 70px;
    background: rgb(254, 246, 244);
}

.our-mission-content h2,
.commonhealth-form-content h2 {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.33em;
    margin-bottom: 30px;
}

.mission-block h3 {
    font-size: 25px;
    margin-bottom: 25px;
}

.mission-list li p {
    padding-left: 5px;
    margin-bottom: 10px;
    font-size: 19px;
}

.interested-tainer-block {
    padding: 85px 55px;
}

.interested-tainer-content h2,
.trusted-content h2 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 60px;
}

.apply-btn {
    background-color: #d81f1f;
    padding: 17px 35px;
    color: #fff !important;
    font-weight: 500;
    font-size: 19px;
    border-radius: 7px;
    text-decoration: none !important;
}

.apply-btn:hover,
.signup_btn:hover,
.more-btn:hover,
.send-btn:hover {
    opacity: .8;
}

.commonhealth -form {
    padding: 130px 55px;
    color: #fff;
}

.commonhealth -form-content .social-media a {
    font-size: 21px;
    margin: 0 5px;
}

.commonhealth -form-content h2 {
    margin-bottom: 35px;
}

.commonhealth -form-content p {
    font-size: 25px;
    margin-bottom: 65px;
}

.commonhealth -form-content .bottom-bor {
    background-image: linear-gradient(to top, #23397f 40%, transparent 40%);
}

.input_form {
    width: 100%;
    height: 55px;
    border: 1px solid #fff;
    padding: 15px 20px;
    outline: none;
    font-size: 16px;
    border-radius: 7px;
    margin-bottom: 20px;
}

.signup_btn {
    background-color: #d81f1f;
    border: 1px solid #d81f1f;
    color: #fff;
    width: 130px;
    height: 55px;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    right: -1px;
    top: 0px;
    line-height: 10px;
    padding-left: 8px;
    border-radius: 0 7px 7px 0;
}

.sqs-img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    margin-bottom: 30px;
    border-radius: 15px;
}

.re-privcy-text {
    font-weight: bold;
    letter-spacing: 0;
    margin-top: 15px;
    display: block;
}

.sqs-layout {
    background-color: #fff;
}


.footer-bg {
    background-color: #141b22;
    padding: 40px 55px 0;
}

.footer-content p {
    font-size: 16px;
    color: #a4abb3;
    margin-bottom: 25px;
}

.footer-menu h4,
.our-link h4 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 10px;
}

.footer-menu li a {
    font-size: 16px;
    color: #a4abb3;
    text-decoration: none;
}

.footer-menu li a:hover {
    color: #d81f1f;
}

.line {
    width: 70px;
    height: 1px;
    background-color: #d81f1f;
    margin-bottom: 25px;
}

.footer-menu {
    padding: 0 103px;
}

.footer-menu li {
    margin-bottom: 8px;
}

.footer-logo {
    margin-bottom: 20px;
}

.footer-content .social-media {
    margin-left: 0;
}

.footer-content .social-media a {
    font-size: 20px;
    margin: 0 12px 0 0;
    color: #fff;
}

.footer-btm {
    color: #a4abb3;
    font-size: 16px;
    text-align: center;
    border-top: 1px solid #43494e;
    padding: 25px 0;
}

.footer-nav a {
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

.footer-logo img {
    width: 79px;
    height: 80px;
}

.sm-title {
    text-transform: uppercase;
}

.about-circle-one {
    position: absolute;
    top: 0;
    left: -35px;
    z-index: -2;
    animation-name: rotateme;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: rotateme;
    -webkit-animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotateme;
    -moz-animation-duration: 15s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: rotateme;
    -ms-animation-duration: 15s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: rotateme;
    -o-animation-duration: 15s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.about-circle-two {
    position: absolute;
    top: 0;
    left: -35px;
    z-index: -2;
    animation-name: rotateme-up;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: rotateme-up;
    -webkit-animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotateme-up;
    -moz-animation-duration: 15s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: rotateme-up;
    -ms-animation-duration: 15s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: rotateme-up;
    -o-animation-duration: 15s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.dapp-img {
    position: relative;
    z-index: 1;
}

.mob-img {
    width: 325px;
    height: 605px;
    margin: 0 auto;
    display: block;
}

.appstore {
    text-align: center;
    justify-content: center;
}

/*-------------- animation Start------------------*/

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
    transition-delay: .2s;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    transform: translateZ(0) scale(1);
}

.mob-img {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
    transition-delay: 0;
}

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
    transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
    transition-duration: 1s;
}

[data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, transform;
}

@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

/*-------------- animation End------------------*/
.bottom-bor {
    color: #d81f1f;
}

/*------------ Home page css End ---------*/


/*---------- How it Works css start ----------*/
.goals-block {
    padding: 75px 55px 40px;
}

.goals-content h1 {
    font-size: 40px;
    margin-bottom: 25px;
    font-weight: bold;
}

.goals-content h3 {
    font-size: 20px;
    margin-bottom: 40px;
}

.goal-img {
    width: 100%;
    height: 680px;
}

.take-training-bg {
    background-color: #507eee;
}

.howtocommonhealth-block {
    padding: 70px 55px;
}

.howtocommonhealth-content h2 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 25px;
}

.howtocommonhealth-content p {
    font-size: 17px;
    margin-bottom: 45px;
    text-align: justify;
}

.howcommonhealth-box img {
    width: 100%;
    height: 855px;
    border-radius: 5px;
    margin-bottom: 25px;
}

.hit-content h3 {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 22px;
}

.hit-content p {
    font-size: 17px;
    margin-bottom: 8px;
    text-align: justify;
    line-height: 23px;
}

.trusted-block {
    padding: 85px 55px;
    background: rgb(254, 246, 244);
}

.trusted-title h2 {
    margin-bottom: 60px;
    font-size: 28px;
}

.trusted-trainer-box img {
    width: 100%;
    height: 395px;
    margin-bottom: 15px;
}

.summary-content h3 a {
    font-size: 21px;
    font-weight: bold;
    color: #000;
    text-decoration: none;
}

.summary-content p {
    font-size: 16px;
    color: #000;
    letter-spacing: 0px;
}

.limits-block {
    padding: 40px 55px;
    background-color: #141b22;
}

.limits-block .p-training-text {
    margin-bottom: 40px;
    padding-top: 120px;
}

.team-img-box {
    padding: 15px;
    border-radius: 100%;
    transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
    border: solid 10px #ebebeb;
    width: 290px;
    margin: 0 auto;
}

.team-img {
    position: relative;
    overflow: hidden;
}

.team-img>img {
    width: 240px;
    height: 240px;
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
    border-radius: 100%;
}

.team-box {
    text-align: center;
    text-decoration: none !important;
    padding: 30px;
}

.team-box a {
    text-decoration: none;
}

.team-content .h3-title {
    margin-top: 25px;
    margin-bottom: 10px;
    transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
}

.h3-title {
    font-size: 24px;
    color: #10161b;
    line-height: 35px;
    font-weight: 800;
}

.team-content span {
    color: #777777;
    font-size: 18px;
}

.team-social {
    margin-top: 15px;
}

.team-social ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    text-decoration: none;
    display: flex;
    justify-content: center;
}

.team-social ul li {
    margin-right: 10px;
}

.team-social ul li a {
    width: 35px;
    height: 35px;
    display: flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: solid 1px #d81f1f;
    color: #d81f1f;
    transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
    font-size: 15px;
    text-decoration: none;
}

.team-social ul li a:hover {
    background-color: #d81f1f;
    color: #ffffff;
    transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
}

.team-box:hover .team-img-box {
    border: solid 10px #d81f1f;
    transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
}

.team-box:hover .team-content .h3-title {
    color: #d81f1f;
    transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
}

.h-icon {
    box-shadow: 0px 3px 13px 0px rgba(216, 31, 31, 0.14);
    background-color: #d81f1f;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    line-height: 60px;
    color: #fff;
    margin-right: 30px;
}

.hit-content {
    flex: 1;
}

.ch-howitwork img {
    width: 100%;
}

.howtocommonhealth -content {
    padding-top: 40px;
}

/*------------- How it Works css End -----------*/

/*-------------- Contact Us Css Start ---------------*/

.contactus-content h1 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 35px;
}

.contactus-content {
    padding-right: 105px;
}

.contactus-content p {
    font-size: 17px;
    margin-bottom: 0px;
}

.contactus-content .social-media a {
    margin: 0 5px;
    font-size: 20px;
}

.more-btn {
    background-color: #233980;
    border: 1px solid #233980;
    color: #fff;
    height: 45px;
    width: 145px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;
}

.contact-form label {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 4px;
}

.input-field {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    padding: 15px 20px;
    font-size: 15px;
    outline: none;
}

.contact-form span {
    font-weight: bold;
}

.text_area {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 15px;
    height: 120px !important;
    padding: 15px 20px;
    font-size: 15px;
    outline: none;
}

.send-btn {
    background-color: #d81f1f;
    border: 1px solid #d81f1f;
    height: 60px;
    width: 225px;
    color: #fff;
    font-size: 17px;
    border-radius: 7px;
    outline: none;
    margin-top: 10px;
    font-weight: bold;
}

.contact-img {
    background-image: url("/public/img/banner-contact.jpg");
    padding-top: 102px;
    height: 350px;
    width: 100%;
}

.contact-img img {
    width: 100%;
}

.contact-text {
    color: #fff;
    font-size: 45px;
    font-weight: bold;
    padding: 97px 69px;
    margin-bottom: 0;
}

.contactus-block {
    background-image: url("/public/img/testimonial_img.jpg");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    padding: 65px 55px;
}

.contact-box {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 19px 20px;
    text-align: center;
    color: #000 !important;
    text-decoration: none !important;
    display: block;
    height: 100%;
}

.contact-box i {
    color: #d81f1f;
    font-size: 30px;
    margin-bottom: 15px;
}

.contact-box h5 {
    margin-bottom: 5px;
    font-weight: 600;
}

.contact-box a {
    color: #777;
    text-decoration: none;
    font-size: 16px;
}

/*-------------- Contact Us Css End ---------------*/

/*----------- privacy policy css start ---------------*/
.sqs-block-content {
    color: #fff;
    text-align: justify;
}

.privacy-policy {
    padding: 85px 70px;
    background-color: #0b0a38;
}

.sqs-block-content h2 {
    font-size: 25px;
    margin-bottom: 25px;
}

.sqs-block-content h3 {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 20px;
}

.sqs-block-content p,
.sqs-block-content ol,
.sqs-block-content ul {
    font-size: 16px;
    margin-bottom: 25px;
}


/*----------- privacy policy css End ---------------*/

.main-banner {
    background-color: #141b22;
    position: relative;
    width: 100%;
    z-index: 0;
    padding-top: 188px;
    padding-bottom: 65px;
}

.main-banner::before {
    content: "";
    position: absolute;
    width: 505px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #d81f1f;
}

.wait-mob-img {
    position: relative;
    z-index: 1;
}

.choose-img {
    text-align: right;
}

.banner-circle-first {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: -1;
    animation-name: rotateme;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: rotateme;
    -webkit-animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotateme;
    -moz-animation-duration: 15s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: rotateme;
    -ms-animation-duration: 15s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: rotateme;
    -o-animation-duration: 15s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@-webkit-keyframes rotateme {
    0% {
        -webkit-transform: rotate(0deg);
        opacity: 1;
    }

    50% {
        -webkit-transform: rotate(180deg);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: rotate(360deg);
        opacity: 1;
    }
}

@-moz-keyframes rotateme {
    0% {
        -moz-transform: rotate(0deg);
        opacity: 1;
    }

    50% {
        -moz-transform: rotate(180deg);
        opacity: 0.7;
    }

    100% {
        -moz-transform: rotate(360deg);
        opacity: 1;
    }
}

@-o-keyframes rotateme {
    0% {
        -o-transform: rotate(0deg);
        opacity: 1;
    }

    50% {
        -o-transform: rotate(180deg);
        opacity: 0.7;
    }

    100% {
        -o-transform: rotate(360deg);
        opacity: 1;
    }
}

@keyframes rotateme {

    0% {
        transform: rotate(0deg);
        opacity: 1;
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
        opacity: 1;
    }
}

.banner-circle-second {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: -1;
    animation-name: rotateme-up;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: rotateme-up;
    -webkit-animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotateme-up;
    -moz-animation-duration: 15s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: rotateme-up;
    -ms-animation-duration: 15s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: rotateme-up;
    -o-animation-duration: 15s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@-webkit-keyframes rotateme-up {
    0% {
        -webkit-transform: rotate(0deg);
        opacity: 1;
    }

    50% {
        -webkit-transform: rotate(180deg);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: rotate(360deg);
        opacity: 1;
    }
}

@-moz-keyframes rotateme-up {
    0% {
        -moz-transform: rotate(360deg);
        opacity: 1;
    }

    50% {
        -moz-transform: rotate(180deg);
        opacity: 0.7;
    }

    100% {
        -moz-transform: rotate(0deg);
        opacity: 1;
    }
}

@-o-keyframes rotateme-up {
    0% {
        -o-transform: rotate(360deg);
        opacity: 1;
    }

    50% {
        -o-transform: rotate(180deg);
        opacity: 0.7;
    }

    100% {
        -o-transform: rotate(0deg);
        opacity: 1;
    }
}

@keyframes rotateme-up {

    0% {
        transform: rotate(360deg);
        opacity: 1;
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(0deg);
        opacity: 1;
    }
}

.banner-circle-first img,
.banner-circle-second img {
    width: 530px;
    max-width: 100%;
}

.wait-listbtn {
    background-color: #d81f1f;
    font-size: 19px;
    color: #fff !important;
    font-weight: 500;
    border-radius: 7px;
    text-decoration: none !important;
    display: block;
    text-align: center;
    margin-top: 45px;
    height: 60px;
    width: 230px;
    line-height: 58px;
}

.banner-overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.banner-blur-bg {
    position: absolute;
    top: -665px;
    left: -540px;
    animation-name: zoom-fade;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: zoom-fade;
    -webkit-animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: zoom-fade;
    -moz-animation-duration: 4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: zoom-fade;
    -ms-animation-duration: 4s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: zoom-fade;
    -o-animation-duration: 4s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.p-traning {
    height: 535px;
    width: auto;
    margin: 0 auto;
    display: block;
}

.custom_btn {
    color: #fff;
    text-decoration: none !important;
    font-size: 20px;
    font-weight: 600;
    border-radius: 7px;
    width: 187px;
    line-height: 17px;
    background-color: transparent;
    border: 2px solid #d81f1f;
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.custom_btn p {
    margin-bottom: 7px;
}

.custom_btn i {
    font-size: 28px;
    line-height: 45px;
    width: 55px;
    text-align: center;
    padding-left: 10px;
}

.custom_btn .fa-apple {
    font-size: 35px;
    line-height: 42px;
    padding-left: 3px;
}

.custom_btn p span {
    font-size: 13px;
}



@-webkit-keyframes zoom-fade {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }
}

@keyframes zoom-fade {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }
}

/*------------ slick slider Css --------------*/
.slider-block .slider {
    position: absolute;
    cursor: pointer;
    top: 120px;
    background-color: transparent;
}

.slider-block {
    display: inline-block;
    width: 100%;
}

.slider-block .slider::before {
    display: none;
}

.slick-prev::before,
.slick-next::before {
    color: #d81f1f;
    font-size: 35px;
    opacity: 1;
}

.slick-prev::before {
    margin-left: -15px;
}

/*------------ slick slider Css End --------------*/

@media screen and (max-width: 1360px) {
    .team-img-box {
        width: 250px;
    }

    .team-img>img {
        width: 200px;
        height: 200px;
    }
}

@media screen and (max-width: 1480px) {
    .main-banner::before {
        width: 390px;
    }

    .banner-circle-first img,
    .banner-circle-second img {
        max-width: 100%;
        width: auto;
    }

    .banner-circle-first {
        right: 30px;
        top: 50px;
        left: auto;
    }

    .banner-circle-second {
        right: 30px;
        top: 50px;
        left: auto;
    }

    .p-traning {
        width: 100%;
    }

    .choose-img img {
        width: 100%;
    }
}

@media only screen and (max-width: 1199px) {
    .contactus-block {
        padding: 67px 70px;
    }

    .footer-menu {
        padding: 0 66px;
    }

    .footer-content p {
        text-align: justify;
    }

    .p-training-text {
        padding-top: 120px;
    }

    .trainer-content {
        padding-top: 0;
    }

    .contactus-content {
        padding-right: 70px;
    }

    .image-title-wrapper h4 {
        font-size: 51px;
    }

    .wrap-p {
        font-size: 17px;
        margin-bottom: 30px;
        text-align: justify;
    }

    .privacy-policy {
        padding: 50px 35px;
    }

    .choose-commonhealth {
        padding: 115px 70px;
    }

    .choose-block {
        padding-left: 0;
    }

    .our-mission-content h2,
    .commonhealth -form-content h2 {
        font-size: 40px;
        margin-bottom: 45px;
    }

    .goal-img {
        height: auto;
    }

    .banner-circle-first,
    .banner-circle-second,
    .banner-blur-bg,
    .banner-overlay-bg {
        display: none;
    }

    .p-training-block {
        padding: 70px;
    }

    .contact-text {
        padding: 97px 70px;
    }

    .team-box {
        padding: 23px;
    }

    .howtocommonhealth-content h2 {
        font-size: 35px;
    }

    .team-img-box {
        width: 185px;
    }

    .team-img>img {
        width: 135px;
        height: 135px;
    }

    .trusted-block {
        padding: 85px 55px 45px;
    }
}

@media only screen and (max-width: 991px) {
    .contactus-content {
        padding-right: 0px;
    }

    .image-title-wrapper {
        padding-top: 80px;
    }

    .image-title-wrapper h4 {
        font-size: 36px;
        margin-bottom: 30px;
        line-height: 49px;
    }

    .footer-logo {
        text-align: center;
    }

    .mission-block {
        margin-bottom: 40px;
    }

    .choose-img img {
        height: auto;
    }

    .howcommonhealth -box,
    .trusted-trainer-box {
        margin-bottom: 35px;
    }

    .navbar-collapse {
        background-color: #fff;
        position: fixed;
        top: 0px;
        right: 0;
        left: 0;
        padding: 25px;
        z-index: 9;
        width: 360px;
        height: 100%;
        box-shadow: 6px 6px 60px 0px rgb(0 0 0 / 10%);
    }

    .header-nav {
        line-height: 34px;
    }

    .head-menu a {
        display: block;
        margin: 0;
    }

    .social-media {
        margin-left: 0;
    }

    .social-media a {
        margin-left: 0;
        margin-right: 10px;
    }

    .main-login {
        margin-top: 10px;
        float: left;
    }

    .close_b {
        position: absolute;
        top: 18px !important;
        font-size: 20px;
        color: #fff;
        z-index: 9;
        right: 20px;
    }

    .head-menu {
        margin-bottom: 10px;
    }

    .navbar-header .navbar-toggler {
        top: 23px;
        font-size: 16px;
    }

    .footer-menu {
        padding: 0;
    }

    .footer-content p {
        margin-bottom: 50px;
    }

    .Index-page-content h1 {
        font-size: 40px;
    }

    .trainer-content {
        padding-top: 40px;
    }

    .Index-page-content {
        padding: 75px 85px;
    }

    .Index-page-content p {
        font-size: 17px;
    }

    .p-training-text {
        padding-top: 60px;
    }

    .our-mission-content h2,
    .commonhealth -form-content h2 {
        font-size: 34px;
    }

    .howtocommonhealth -block {
        padding: 15px 55px 45px;
    }

    .ch-howitwork img {
        margin-top: 30px;
    }

    .limits-block {
        text-align: center;
        padding: 60px 55px;
    }

    .limits-block .p-training-text {
        padding-top: 30px;
    }

    .team-box {
        margin-bottom: 10px;
        padding: 15px;
    }

    .trusted-block {
        padding: 70px 55px 30px;
    }

    .contactus-content h1 {
        margin-bottom: 20px;
        font-size: 30px;
    }

    .contactus-block {
        background-color: #fff;
    }

    .contactus-block {
        padding: 40px 70px;
    }

    .team-img>img {
        width: 105px;
        height: 105px;
    }

    .team-img-box {
        width: 155px;
    }

    .team-content .h3-title {
        margin-bottom: 5px;
    }

    .h3-title {
        font-size: 20px;
    }

    .team-content span {
        font-size: 15px;
    }
}


@media screen and (min-width: 768px) {
    .collapse.navbar-collapse {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .contactus-block {
        padding: 50px 15px;
    }

    .more-btn {
        margin-bottom: 40px;
    }

    .contactus-content h1 {
        font-size: 32px;
        margin-bottom: 15px;
    }

    .Index-page-content {
        padding: 50px 15px;
    }

    .Index-page-content h1 {
        font-size: 34px;
        margin-bottom: 19px;
    }

    .p-training-block {
        padding: 60px 30px;
    }

    .appstore img {
        width: 155px;
        height: 50px;
        margin: 5px;
    }

    .interested-tainer-block {
        padding: 65px 15px;
    }

    .our-mission {
        padding: 70px 15px;
    }

    .commonhealth -form {
        padding: 65px 15px;
    }

    .commonhealth -form-content p {
        margin-bottom: 45px;
    }

    .footer-content {
        text-align: center;
    }

    .footer-content p {
        margin-bottom: 20px;
    }

    .choose-content h2 {
        font-size: 35px;
    }

    .our-mission-content h2,
    .commonhealth -form-content h2 {
        font-size: 32px;
        margin-bottom: 40px;
    }

    .interested-tainer-content h2,
    .trusted-content h2 {
        font-size: 32px;
        margin-bottom: 50px;
    }

    .apply-btn {
        font-size: 16px;
        padding: 17px 30px;
    }

    .re-privcy-text {
        margin-top: 45px;
    }

    .footer-logo img {
        margin-top: 30px;
    }

    .Waitlist-section {
        padding: 50px 0px;
    }

    .image-title-wrapper {
        padding-left: 0;
        padding-top: 55px;
    }

    .choose-commonhealth {
        padding: 70px 15px;
    }

    .choose-content p {
        margin-bottom: 35px;
    }


    .commonhealth -form,

    .trusted-block,
    .limits-block {
        padding: 60px 30px;
    }

    .goals-block {
        padding: 60px 0px;
    }

    .howtocommonhealth -block {
        padding: 20px 0px 55px;
    }

    .howcommonhealth -box img,
    .trusted-trainer-box img {
        height: auto;
    }

    .howtocommonhealth -content p {
        margin-bottom: 44px;
    }

    .footer-menu {
        text-align: center;
    }

    .line {
        margin: 0 auto 25px;
    }

    .footer-menu h4,
    .our-link h4 {
        text-align: center;
    }

    .footer-menu ul {
        margin-bottom: 30px;
    }

    .social-media {
        margin-top: 10px;
        text-align: center;
    }

    .footer-bg {
        padding: 10px 10px 0;
    }

    .main-banner {
        padding-top: 75px;
        padding-bottom: 15px;
    }

    .navbar-header {
        padding: 12px 0;
    }

    .wait-mob-img .mob-img {
        margin: 50px auto 0;
    }

    .mission-block,
    .mission-list {
        margin-bottom: 0;
    }

    .main-banner::before {
        width: 160px;
    }

    .contact-text {
        padding: 97px 18px;
    }

    .goals-block {
        padding: 57px 0px 40px;
    }

    .howtocommonhealth-block {
        padding: 45px 0;
    }
}


@media only screen and (max-width: 575px) {
    .contactus-content {
        padding-right: 0;
    }

    .image-title-wrapper {
        padding-left: 0;
    }

    .Index-page-content h1 {
        font-size: 25px;
        margin-bottom: 15px;
        line-height: 35px;
    }

    .goals-content h1 {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .goals-content h3 {
        font-size: 18px;
    }

    .howtocommonhealth -content h2 {
        font-size: 31px;
        margin-bottom: 15px;
    }

    .hit-content h3 {
        font-size: 23px;
    }

    .main-banner::before {
        display: none;
    }

    .p-traning,
    .mob-img {
        height: auto;
    }

    .choose-content h2 {
        font-size: 30px;
        margin-bottom: 50px;
    }

    .p-training-text {
        font-size: 17px;
        margin-bottom: 35px;
        font-weight: normal;
    }

    .footer-bg {
        padding: 10px 15px 0;
    }

    .our-mission-content h2,
    .commonhealth -form-content h2 {
        font-size: 25px;
    }

    .mob-img {
        width: 100%;
    }

    .contact-img {
        height: auto;
    }

    .contact-text {
        text-align: center;
        padding: 55px 18px;
        font-size: 30px;
    }

    .h-icon {
        display: block;
        margin: 0 auto 20px;
    }

    .hit-content {
        text-align: center;
    }

    .send-btn {
        width: 100%;
    }

    .custom_btn {
        width: 210px;
        margin: 20px auto;
    }

    .ch-content p {
        margin-bottom: 25px;
    }

    .ch-content h2 {
        margin-bottom: 10px;
    }

    .howtocommonhealth-content h2 {
        font-size: 26px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 425px) {

    .our-mission-content h2,
    .commonhealth -form-content h2 {
        font-size: 25px;
    }

    .image-title-wrapper h4 {
        font-size: 29px;
        line-height: 39px;
        margin-bottom: 20px;
    }

    .navbar-collapse {
        width: 100%;
    }

    .limits-block .p-training-text {
        padding-top: 0;
    }

    .limits-block,
    .p-training-text {
        text-align: justify;
    }

    .appstore {
        text-align: center;
    }

}

@media only screen and (max-width: 395px) {
    .team-img>img {
        width: 165px;
        height: 165px;
    }

    .team-img-box {
        width: 215px;
    }
}