
.video-container{
    position: relative;
    background-Color: #333;
    color: white;
    border-Radius: 8px;
    overflow: hidden;
    height: 83vh    
}
.controls{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; 
    display:flex;
    justify-content:center;
    gap:20px;
    padding:15px;
    border-radius: 0 0 8px 8px;
}
.control-icon{
    border:none;
    width:40px;
    height:40px;
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:18px;
    cursor:pointer;
    transition: 'all 0.3s'
}
.my-screen{
    position: absolute;
    width: 180px;
    height: 120px;;
    bottom: 10px;
    right: 10px;
    background-Color: #ddd;
    color: #333;
    /* padding: 6px; */
    border-Radius: 6px;
    font-Size: 14px;
    display: flex;
}
.my-screen img{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    aspect-ratio: 16 / 9;
    border-radius: 6px;
}
.my-screen .mydisplay{
    width: 100% !important;
    height: 100% !important;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 9;
}
.my-screen .mydisplay video{
    object-fit: cover;
    aspect-ratio: 16/9;
    width: 100%;
    height: 100% !important;
    border: 1px solid #adadad;
    border-radius: 6px;
}
.remote-screen{
    width: 100% !important;
    height: 100% !important;
}
.remote-screen video{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}
@media (max-width:767px){
    .my-screen{
        width: 20vw !important;
    }
}